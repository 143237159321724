import { Config } from 'taildwindcss'

const config: Config = {
  safelist: ['w-24', 'flex-grow'],
  theme: {
    screens: {
      sm: '640px',
      md: '1024px',
      lg: '1366px',
      xl: '1920px',
    },
    colors: {
      'price-variant': {
        good: '#4bbf7c',
        attention: '#cf6679',
      },
      current: 'currentColor',
      blue: {
        500: '#223855',
        dark: '#3B4D65',
      },
      secondary: {
        100: '#C9CFFF',
        200: '#A2B0FF',
      },
      overview: '#A8B9CE',
      // Primaries
      'p-blue': {
        50: '#071830',
        100: '#333D50',
        200: '#213248',
        300: '#232340',
        500: '#1D283B',
        600: '#13243A',
        700: '#162033',
        800: '#27295A',
        900: '#0E172A',
      },

      'nav-box': '#031022',

      'p-yellow': {
        50: '#FFF7E0',
        100: '#FBE9A9',
        200: '#FEEBB2',
        300: '#FDD24C',
        400: '#FDC723',
        500: '#FEDE80',
        600: '#FDBD00',
        700: '#D3A020',
        800: '#F7C11F',
        900: '#FFCE56',
      },

      'p-green': {
        300: '#84E1BC',
        400: '#71B866',
        500: '#4CBAB7',
        600: '#057A55',
        700: '#87C9BB',
        800: '#03543F',
        900: '#458747',
      },

      // Neutrals
      'n-gray': {
        '050': '#F5F7FA',
        100: '#E4E7EB',
        150: '#A9A9A9',
        200: '#CBD2D9',
        300: '#9AA5B1',
        400: '#7B8794',
        500: '#616E7C',
        600: '#52606D',
        700: '#3E4C59',
        800: '#323F4B',
        900: '#1F2933',
      },

      's-red': {
        100: '#F6B6B4',
        300: '#CF6679',
        400: '#E95348',
        500: '#E62248',
        600: '#E02424',
        700: '#C81E1E',
        800: '#903C60',
        900: '#771D1D',
      },

      's-pink': {
        300: '#ED94BA',
      },

      costsModel: {
        clay: '#FC6B00',
        brine: '#FD8B00',
        jadarite: '#FDAF00',
        lepidolite: '#FDC723',
        pegmatite: '#FEDE80',
        spodumene: '#FEEBB2',
        other: '#A9A9A9',
      },

      rawMaterialDemand: {
        lithium: '#FDBD00',
        nickel: '#FF358A',
        graphite: '#5D5D5D',
        manganese: '#FF833D',
        cobalt: '#55C0EE',
      },

      cathodes: {
        'NCM 111': '#1E738A', // blue
        'NCM 523': '#87405E', // burgundy
        'NCM 622': '#536983', // grey
        'NCM 811': '#12644E', // green
        NCA: '#9FC465', // lime
        LCO: '#A0AF46', // olive
        LFP: '#F3BC50', // yellow
        LMO: '#F88044', // orange
        LMNO: '#F14F4D', // red
        LTO: '#B64444', // maroon
        Other: '#a9a9a9', // brown
        'NCM mid nickel': '#1E738A',
        'NCM low nickel': '#87405E', // burgundy
        'NCM high nickel': '#536983', // grey
        '5V Mn based': '#12644E',
      },

      chart: {
        grey: '#536983',
        burgundy: '#87405E',
        blue: '#1E738A',
        green: '#12644E',
        lime: '#9FC465',
        olive: '#A0AF46',
        yellow: '#F3BC50',
        orange: '#F88044',
        red: '#F14F4D',
        lightBurgundy: '#B64444',
      },

      lithiumSupplyAndDemandMined: {
        Demand: '#CF6679',
        'Primary Supply': '#FDBD00',
        'Secondary Supply': '#5375FF',
      },

      lithiumSupplyAndDemandChemical: {
        Demand: '#CF6679',
        Supply: '#FDBD00',
      },

      lithiumSupplyAndDemandChemicalProduct: {
        Demand: '#cf6679',
        'Lithium Carbonate': '#27445d',
        'Lithium Hydroxide': '#84445f',
      },

      lithiumOverviewBalance: {
        'Balance +/-': '#FDBD00',
      },

      region: [
        '#EFBD4E',
        '#D97C54',
        '#CA1642',
        '#5041AC',
        '#106318',
        '#834484',
        '#266171',
        '#A9A9A9',
        '#4363D8',
        '#FDC723',
        '#E6194B',
      ],

      regionCost: {
        south_america: '#EFBD4E',
        oceania: '#D97C54',
        north_america: '#CA1642',
        africa: '#5041AC',
        europe: '#106318',
        china: '#834484',
      },

      countriesColors: {
        Demand: '#CF6679',
        Angola: '#521630',
        Argentina: '#3ABBD7',
        Australia: '#8239CB',
        Austria: '#B88299',
        Bolivia: '#1B3F65',
        Brazil: '#10900D',
        Canada: '#862882',
        Chile: '#415366',
        China: '#CA1642',
        Czechia: '#165366',
        DRC: '#276274',
        Ethiopia: '#064634',
        Finland: '#959D2A',
        France: '#11654E',
        Germany: '#A7AE4E',
        Ghana: '#67941E',
        Ireland: '#E4A522',
        'Ivory Coast': '#F8DFAC',
        Japan: '#3B57BE',
        Madagascar: '#F26B27',
        Mali: '#C1E18D',
        Mexico: '#79C058',
        Mongolia: '#C2561F',
        Namibia: '#FFB5B4',
        'New Zealand': '#FFA679',
        Nigeria: '#81B926',
        Peru: '#451A1A',
        Portugal: '#589585',
        Russia: '#975252',
        Rwanda: '#973231',
        'Saudi Arabia': '#CA782D',
        Serbia: '#2E7B66',
        'South Africa': '#81425D',
        'South Korea': '#42CAB2',
        Spain: '#671B3C',
        Sweden: '#F28148',
        Thailand: '#CDE7A4',
        'United Kingdom': '#4D3BBE',
        Ukraine: '#A0C367',
        'United States': '#E0B54C',
        Zimbabwe: '#B44242',
        'United Arab Emirates': '#562020',
        Romania: '#677585',
        'Poland/Germany': '#B6831B',
        India: '#678F9B',
        Indonesia: '#D1D78D',
      },

      technologyIndex: {
        LCO: '#84445F', // burgundy
        LFP: '#27445D', // blue
        'NCM 111': '#266171', // swamp
        'NCM 523': '#10634D', // green
        'NCM 622': '#9FAF49', // olive
        'NCM 811': '#A0C369', // lightGreen
        NCA: '#EFBD4E', // yellow
        LMO: '#F88044', // orange
        LMNO: '#F65653', // red
        Other: '#B64444', // lightBurgundy
        Other2: '#562020',
        Other3: '#00402F',
        Other4: '#89A300',
        Other5: '#C0C9B1',
        Other6: '#D1A646',
        Other7: '#AD4F20',
        Other8: '#E08786',
        Other9: '#A67171',
        Other10: '#4B3030',
        Other11: '#A9A9A9',
      },

      anodeTechnologies: {
        Natural: '#1E738A',
        Synthetic: '#87405E',
        MCMB: '#F3BC50',
        Silicon: '#F88044',
        LTO: '#A0AF46',
        Other: '#B64444',
      },

      capacityIndex: {
        China: {
          currentProduction: '#E6194B',
          existing: '#EB476F',
          underConstruction: '#F07593',
          planning: '#F5A3B7',
        },
        Japan: {
          currentProduction: '#4363D8',
          existing: '#6982E0',
          underConstruction: '#8EA1E8',
          planning: '#B4C1EF',
        },
        'South Korea': {
          currentProduction: '#42D4F4',
          existing: '#68DDF6',
          underConstruction: '#8EE5F8',
          planning: '#B3EEFB',
        },
        'Asia (Other)': {
          currentProduction: '#31A3F5',
          existing: '#5AACF7',
          underConstruction: '#83B9F9',
          planning: '#ADD6FB',
        },
        Europe: {
          currentProduction: '#04BA00',
          existing: '#36C833',
          underConstruction: '#68D666',
          planning: '#9BE399',
        },
        'North America': {
          currentProduction: '#FFCE56',
          existing: '#FFD878',
          underConstruction: '#FFE29A',
          planning: '#FFEBBB',
        },
        Other: {
          currentProduction: '#A9A9A9',
          existing: '#BABABA',
          underConstruction: '#CBCBCB',
          planning: '#DDDDDD',
        },
      },

      locations: {
        China: '#E6194B',
        Japan: '#4363D8',
        'South Korea': '#42D4F4',
        'Asia (excl China)': '#31A3F5',
        Europe: '#04BA00',
        'North America': '#FFCE56',
        Other: '#A9A9A9',
      },

      lithium: {
        'Spodumene (6%) - FOB Australia': '#1E738A',
        'Lithium Hydroxide (Min 55.0%) - CIF EU': '#87405E',
        'Lithium Hydroxide (Min 55.0%) - FOB N America': '#536983',
        'Lithium Hydroxide (Min 55.0%) - Global Weighted Average': '#9FC465',
        'Lithium Hydroxide (Min 56.5%) - CIF Asia (combined)': '#A0AF46',
        'Lithium Hydroxide (Min 56.5%) - CIF Asia (contract)': '#F3C4CF',
        'Lithium Hydroxide (Min 56.5%) - CIF Asia (spot)': '#40CFFF',
        'Lithium Hydroxide (Min 56.5%) - EXW China': '#F3BC50',
        'Lithium Carbonate (Min 99.0%) - FOB S America': '#F88044',
        'Lithium Carbonate (Min 99.0%) - CIF EU': '#F14F4D',
        'Lithium Carbonate (Min 99.0%) - CIF N America': '#B44242',
        'Lithium Carbonate (Min 99.0%) - Global Weighted Average': '#00402F',
        'Lithium Carbonate (Min 99.2%) - CIF Asia (combined)': '#10634D',
        'Lithium Carbonate (Min 99.2%) - CIF Asia (contract)': '#FF007F',
        'Lithium Carbonate (Min 99.2%) - CIF Asia (spot)': '#a4a5a5',
        'Lithium Carbonate (Min 99.5%) Battery - EXW China': '#D1A646',
        'Lithium Carbonate (Min 99.0%) Technical - EXW China': '#AD4F20',
        'Lithium Carbonate': '#42CAB2',
        'Lithium Hydroxide': '#FDBD00',
      },

      lithiumIndex: {
        anode: '#D1A646',
        cathode: '#D1A646',
        lithium: '#D1A646',
        carbonate: '#F14F4D',
        hydroxide: '#9FC465',
        graphite: '#F19C4D',
      },

      priceRanges: {
        'Lithium Carbonate': '#FDBD00',
        'Lithium Hydroxide': '#5375FF',
        Spodumene: '#F14F4D',
        Natural: '#FDBD00',
        Synthetic: '#5375FF',
        'Nickel Containing': '#FDBD00',
        'Alternative Chemistries': '#5375FF',
        'DDP China': '#5375FF',
        'FOB China': '#FDBD00',
      },

      tier: {
        'Tier 1': '#FDBD00',
        'Tier 2': '#FEDE80',
        'Tier 3': '#FFF7E0',
      },

      statuses: {
        production: '#FD9C00',
        capacity: '#F3BC50',
      },

      'chart-gride-line-color': '#2F3D51',

      'ore-type': [
        '#4B3030',
        '#A67171',
        '#E08786',
        '#F65653',
        '#B44242',
        '#562020',
        '#00402F',
        '#10634D',
        '#89A300',
        '#9FAF49',
        '#A0C369',
        '#C0C9B1',
        '#84445F',
        '#D1A646',
        '#AD4F20',
        '#F88044',
        '#EFBD4E',
        '#27445D',
        '#266171',
        '#42CAB2',
        '#9FC465',
        '#266171',
      ],

      country: {
        China: '#E6194B',
        Japan: '#4363D8',
        USA: '#FFCE56',
        Australia: '#9B42F4',
        Brazil: '#04BA00',
        Argentina: '#42D4F4',
        Canada: '#E619DE',
        other1: '#E68933',
        other2: '#4BE7CB',
        other3: '#C1A4FF',
        other4: '#CC7878',
        other5: '#6198EA',
        India: '#57EEF8',
        'South Korea': '#39B1CF',
        Taiwan: '#009990',
        US: '#D0AC51',
        Netherlands: '#ffa500',
        Norway: '#ffffff',
        Sweden: '#ffff00',
        Other: '#8B8E93',
      },

      brokenByEndUsage: {
        'Li-ion battery': '#4363D8',
        Lubricants: '#FC6B00',
        Carburisation: '#FD9C00',
        'Carbon brushes': '#FDBD00',
        'Graphite shapes': '#FDC723',
        'Friction products': '#FDD24C',
        'Expanded graphite': '#FEDE80',
        'Refractory and foundry': '#FEEBB2',
        'Other uses': '#979797',
        'Battery Demand': '#4363D8',
        'Total Natural Graphite Demand': '#9395A3',
        'Other Demand': '#FDC723',
      },

      regions: [
        '#B44242',
        '#F65653',
        '#F88044',
        '#9FC465',
        '#84445F',
        '#27445D',
        '#EFBD4E',
        '#41A6AC',
      ],

      status: {
        Operating: '#1E738A',
        'Brownfield Expansion': '#F14F4D',
        'Highly Probable': '#3E9947',
        Probable: '#9FC465',
        Possible: '#A0AF46',
      },

      evSales: {
        'Passenger & Light Duty Vehicles': '#DAA609',
        'Fleet Vehicles (Buses, Taxis etc.)': '#4A69E2',
        BEV: '#BD3E3E',
        PHEV: '#098FDA',
      },

      naturalGraphite: {
        OTHER: '#436181',
        BASE: '#166CC9',
        DOWNSIDE: '#FDC723',
        UPSIDE: '#B44242',
      },

      endMarketDemand: {
        EV: '#B44242',
        ESS: '#FFCE56',
        Portables: '#31A3F5',
      },

      marketBalanceStatuses: {
        'Balance - Operational': '#FDD24C',
        'Balance - Operational, highly probable': '#CF6679',
      },

      datePicker: {
        black: '#2a3e53',
        blackAux: '#001a30',
        gray: '#2c3d53',
        yellow: '#ffba00',
      },

      pricesChartGridColor: 'rgba(255, 255, 255, 0.16)',
      pricesChartXAxisGridColor: 'rgba(255, 255, 255, 0.04)',

      error: '#D06679',

      // Shades
      white: '#fff',
      black: '#000',
      grey: 'rgba(255, 255, 255, 0.12)',
      transparent: 'transparent',
      elevationOverlay: 'rgba(255, 255, 255, 0.16)',
      highEmphasis: 'rgba(255, 255, 255, 0.87)',

      felix: {
        primary: {
          yellow: {
            '050': '#FFF7E0',
            100: '#FEEBB2',
            200: '#FEDE80',
            300: '#FDD24C',
            400: '#FDC723',
            500: '#FDBD00',
            600: '#FDAF00',
            700: '#FD9C00',
            800: '#FD8900',
            900: '#E37C00',
          },
        },
        secondary: {
          blue: {
            light: {
              '050': '#EAECFF',
              100: '#C9CFFF',
              200: '#A2B0FF',
              300: '#7890FF',
              400: '#5375FF',
              500: '#235AFD',
              600: '#1C50F1',
              700: '#0345E4',
              800: '#0039D9',
              900: '#0021C8',
            },
            dark: {
              '050': '#E8EDF4',
              100: '#C3CCD8',
              200: '#A8B4C3',
              300: '#7A889C',
              400: '#55667C',
              500: '#3B4D65',
              600: '#283A51',
              700: '#14263D',
              800: '#071830',
              900: '#020D1D',
            },
          },
        },
        semantic: {
          error: '#CF6679',
          good: '#4BBF7C',
          warning: '#FA9131',
          neutral: '#1885E9',
        },
      },
    },
    extend: {
      fontSize: {
        xxs: '0.625rem',
      },
      fontFamily: {
        sans: ['Montserrat', 'sans-serif'],
        lato: ['Lato', 'sans-serif'],
      },
      listStyleType: {
        square: 'square',
      },
      minHeight: (theme) => ({
        ...theme('spacing'),
      }),
      transitionProperty: {
        'max-height': 'max-height',
      },
    },
  },
}

export default config
